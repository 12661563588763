body, html {
    min-width: 1280px;
    height: 100%;
}

#root {
    height: 100%;
    position: relative;
}

.ant-avatar.ant-avatar-icon i {
    margin-right: 0;
}

.ant-layout-header {
    padding: 0 120px;
    background: #222;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: #222;
}

.ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
}

.additional-page-header-content > .ant-page-header-footer {
    margin-top: 0;
}

.ant-collapse {
    border: 1px solid #e8e8e8 !important;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #e8e8e8 !important;
}

.ant-collapse-content {
    border-top: 1px solid #e8e8e8 !important;
}